import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VNavigationDrawer,{attrs:{"app":"","temporary":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"pa-2"},[_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(!_vm.isAuthenticated),expression:"!isAuthenticated"}],attrs:{"color":"primary","large":""},on:{"click":function($event){return _vm.signIn()}}},[_vm._v(" Log in ")]),_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.isAuthenticated),expression:"isAuthenticated"}],attrs:{"color":"primary","large":""},on:{"click":function($event){return _vm.signOut()}}},[_vm._v(" Log out ")])],1)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,{attrs:{"dense":"","nav":""}},[_c(VSubheader,{staticClass:"text-uppercase font-weight-bold"},[_vm._v("Menu")]),(_vm.canUploadBanners)?_c(VBtn,{staticClass:"mx-1",attrs:{"to":"/admin/banners","text":""}},[_vm._v(" Manage Banners ")]):_vm._e(),(_vm.canUploadBulletin)?_c(VBtn,{staticClass:"mx-1",attrs:{"to":"/admin/bulletin","text":""}},[_vm._v(" Manage Bulletin ")]):_vm._e()],1)],1),_c(VBtn,{staticClass:"d-md-none drawer-button",attrs:{"rounded":""},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-menu")])],1),_c(VAppBar,{attrs:{"app":"","height":"80"}},[_c(VContainer,{staticClass:"py-0 px-0 px-sm-2 fill-height",attrs:{"fluid":_vm.isFluid}},[_c('router-link',{staticClass:"d-flex align-center text-decoration-none mr-2",attrs:{"to":"/"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('span',{staticClass:"font-weight-black text-uppercase",attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v(" Toronto Central Adventist Church ")])]}}])}),_c('div',{staticClass:"d-none d-md-block"},[(_vm.canUploadBanners)?_c(VBtn,{staticClass:"mx-1",attrs:{"to":"/admin/banners","text":""}},[_vm._v(" Manage Banners ")]):_vm._e(),(_vm.canUploadBulletin)?_c(VBtn,{staticClass:"mx-1",attrs:{"to":"/admin/bulletin","text":""}},[_vm._v(" Manage Bulletin ")]):_vm._e()],1),_c(VSpacer),_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(!_vm.isAuthenticated),expression:"!isAuthenticated"}],attrs:{"color":"primary","large":""},on:{"click":function($event){return _vm.signIn()}}},[_vm._v(" Log in ")]),_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.isAuthenticated),expression:"isAuthenticated"}],attrs:{"color":"primary","large":""},on:{"click":function($event){return _vm.signOut()}}},[_vm._v(" Log out ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }